//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    surtitle: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      optionTestimonial: {
        init: true,
        spaceBetween: 25,
        slidesPerView: 1,
        // centeredSlides: true,
        loop: true,
        speed: 1000,
      },

      testimonials: [
        {
          picture: "temoignage-goddyns.png",
          altPicture: "Robin GODDYN",
          text: "De la conception du logo et du site internet à la création de  magnifiques étiquettes, Vous avez constamment su répondre de manière exceptionnelle à ces besoins ! Je tiens encore à remercier toute l'équipe pour son dévouement et son excellent travail.",
          name: "Robin GODDYN",
          job: "Gérant — Goddyn’s Finest Cakes",
          logo: "logo-goddyns.png",
          altLogo: "logo Goddyns",
          color: "#004646",
          bgColor: "#F6FFD0",
          width: "147",
          height: "54",
        },
        {
          picture: "temoignage-zenith.png",
          altPicture: "Jérôme DAAB",
          text: "Merci Nancomcy pour votre créativité et votre efficacité&nbsp;!",
          name: "Jérôme DAAB",
          job: "Directeur — Zenith du Grand Nancy",
          logo: "logo-nancy-open-air.png",
          altLogo: "logo Goddyns",
          color: "#927",
          bgColor: "#FFE1F3",
          width: "180",
          height: "67",
        },
        {
          picture: "temoignage-velaine.png",
          altPicture: "Laurent PARISOT",
          text: "À mon sens, l'avantage premier de NANCOMCY, c'est sa palette de compétences très riche dans des domaines variés. si je n'ai qu'un mot à dire c'est : complet, voire très complet.",
          name: "Laurent PARISOT",
          job: "Gérant — Velaine Immo",
          logo: "logo-velaine.png",
          altLogo: "logo Velaine Immo",
          color: "#2A47E9",
          bgColor: "#CDF5F2",
          width: "188",
          height: "66",
        },
        {
          picture: "temoignage-savonniere.png",
          altPicture: "Matthieu GIACOMAZZI",
          text: "Une équipe réactive, humaine et ouverte au dialogue. Un suivi de projet qualitatif avec des intervenants responsables et autonomes. Nous allons faire de grandes choses ensemble j'en suis persuadé.",
          name: "Matthieu GIACOMAZZI",
          job: "Gérant — La Savonnière du Moulin",
          color: "#F85325",
          bgColor: "#FDEDDA",
        },
      ],
    };
  },

  mounted() {
    this.firstSLideTestimonialEffect();
  },

  beforeDestroy() {
    this.swiperTestimonial.destroy(true, true);
  },

  methods: {
    nextTesti() {
      this.swiperTestimonial.slideNext();
    },
    prevTesti() {
      this.swiperTestimonial.slidePrev();
    },

    firstSLideTestimonialEffect() {
      const el = document.querySelector(".testimonial-not-active");
      const io = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              el.classList.remove("testimonial-not-active");
              io.unobserve(el);
            }
          });
        },
        {
          rootMargin: "0% 0% -30% 0%",
        }
      );

      io.observe(el);
    },
  },
};
