let TYPEFORM_INSTANCE = null;

const importTypeform = () => import("@typeform/embed");

const getTypeform = () => {
  if (!TYPEFORM_INSTANCE) {
    return importTypeform().then((typeform) => {
      import("@typeform/embed/build/css/widget.css");
      TYPEFORM_INSTANCE = typeform;
      return typeform;
    });
  }

  return Promise.resolve(TYPEFORM_INSTANCE);
};

export const createTypeformWidget = (...params) => {
  getTypeform().then(({ createWidget }) => {
    createWidget(...params);
  });
};
