//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      isActive: false,
      video: "",
    };
  },

  computed: {
    linkVideo() {
      return "https://static.nancomcy.fr/static/videos/" + this.video + ".mp4";
    },
  },

  methods: {
    toggle(link = "") {
      this.video = link;
      this.isActive = !this.isActive;
      setTimeout(() => {
        document.querySelector(".videoRef").play();
      }, 500);
    },
  },
};
