//
//
//

export default {
  mounted() {
    if (this.$route.path === "/generateur-signature/") {
      document.body.style.cursor = "auto";
    }
  },
};
