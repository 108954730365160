import { gsap } from "gsap";

const transition = {
  appear: true,
  css: false,
  mode: "out-in",
  enter(el, done) {
    gsap.timeline().to(
      ".transition-enter",
      {
        height: "0",
        onComplete: () => {
          document.body.style.overflow = "unset";
        },
      },
      1
    );
  },
  leave(el, done) {
    gsap.timeline().to(
      ".transition-leave",
      {
        height: "100vh",
        onComplete: done,
      },
      0
    );
  },
};

export default transition;
