//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data: () => ({
    idForm: "xuefmtzg",
  }),

  computed: {
    isFormActive() {
      return this.$store.state.form.isFormActive;
    },
  },

  mounted() {
    const events = ["scroll", "pointermove", "click", "touchstart"];
    events.forEach((e) => {
      window.addEventListener(e, this.initForm);
    });
  },

  methods: {
    initForm(e) {
      const scriptPf = document.querySelector(
        "script[src='https://paperform.co/__embed.min.js']"
      );
      if (!scriptPf) {
        const script = document.createElement("script");
        script.src = "https://paperform.co/__embed.min.js";
        document.body.appendChild(script);
      }

      e.currentTarget.removeEventListener(e.type, this.initForm);
    },
    toggleBanner() {
      this.$store.commit("form/toggle");
    },
  },
};
