//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: {
      type: String,
      default:
        "Vous vous posez des questions, l’agence de communication NANCOMCY y répond.",
    },
    faq: {
      type: Array,
      default: () => [
        {
          question: "Pourquoi faire appel à une agence de communication ?",
          answer: `En confiant votre communication à <a class="link-mesh to-html" aria-link="contact" href="/contact">NANCOMCY</a>, vous mettez toutes les chances de votre côté pour renforcer l’image de marque de votre entreprise, améliorer votre visibilité sur internet et sur les réseaux sociaux ou lancer une campagne de publicité forte et efficace. En tant que spécialistes de la communication, les membres de l’équipe NANCOMCY combinent leurs expertises pour élaborer des stratégies adaptées à vos besoins et à votre budget dans différents domaines tels que le marketing, la création de site internet, la gestion des réseaux sociaux, le motion design… Externaliser votre communication c’est aussi permettre à vos équipes de se concentrer sur leur cœur de métier et de les dégager de tâches qu’elles ne maîtrisent pas forcément. Nous nous occupons de la planification, de la création et de l’exécution de votre projet sans que cela n’impacte votre production. Nous apportons des idées fraîches pour différencier votre entreprise et nous nous adaptons aux évolutions de votre marché et aux nouvelles tendances. Avec NANCOMCY vous bénéficiez d’une perspective objective sur votre communication, vous gagnez en visibilité et vos résultats s’en ressentent.`,
          active: false,
        },
        {
          question:
            "Pourquoi choisir une agence de communication située à proximité ?",
          answer:
            "NANCOMCY est attaché à son ancrage local, en effet notre proximité géographique avec nos clients et partenaires facilite les échanges sur les détails des projets, le partage d’idées et la compréhension de l’écosystème dans lequel ils évoluent. Être proche de vous signifie aussi que vous disposez d’une plus grande réactivité et de plus de flexibilité dans le suivi du bon déroulement de votre projet. Par ailleurs, nous connaissons parfaitement le marché local ce qui booste les partenariats locaux et la mise en relation avec des prestataires, les collaborations et les opportunités commerciales pour votre entreprise. En choisissant NANCOMCY pour votre communication, vous participez à la croissance de l’activité économique de la région, c’est finalement un partenariat gagnant pour tous !",
          active: false,
        },
        {
          question:
            "Comment se passe un accompagnement type avec notre agence de communication ?",
          answer: `NANCOMCY propose un accompagnement personnalisé en fonction de vos besoins spécifiques, du type de projet et des services demandés. Lors de la phase de démarrage, nous discutons de vos objectifs et de vos besoins, de votre entreprise, de votre cible, et de l’ensemble des éléments pertinents pour la réalisation de votre projet. Nous évaluons votre identité de marque actuelle, votre positionnement, vos concurrents et les opportunités offertes par votre marché. Sur la base de ces analyses, nous vous présentons une proposition détaillée fonction de votre budget. Nous pouvons travailler sur une stratégie de communication globale ou axer nos efforts sur une ou plusieurs strates : <a class="link-mesh to-html" aria-link="graphisme" href="/prestations/identite-visuelle/">création graphique</a>, contenu éditorial, motion design, web, marketing, social media… Nous vous tenons informé tout au long du processus de création et nous réalisons des ajustements pour que le résultat vous convienne parfaitement. Après la livraison, nous évaluons les résultats et les retours afin d’apprécier l’accueil et l’engagement de votre cible, le retour sur investissement et les performances de chaque projet.`,
          active: false,
        },
        {
          question:
            "Quels sont les services proposés par une agence de communication ?",
          answer: `Avec NANCOMCY, votre communication prend de l’envergure. Vous avez accès à un choix varié de services et à l’expertise de vrais professionnels. Bénéficiez du développement de votre plan de communication grâce à une stratégie pensée sur mesure, obtenez une <a class="link-mesh to-html" aria-link="strategy" href="/prestations/accompagnement/">identité de marque</a> qui comprendra logo, couleurs, typographie, et éléments graphiques associés. Confiez la gestion de votre réputation en ligne à nos experts et soignez votre stratégie marketing et votre référencement. NANCOMCY propose également un service de création de supports visuels, de motion design, de création de site web et de vidéos. Vous êtes accompagné de A à Z , y compris dans la phase de formation à la gestion de votre site ou de vos réseaux sociaux.`,
          active: false,
        },
        {
          question: "Qui a besoin d’une agence de communication ?",
          answer:
            "Quelle que soit la taille de votre structure, NANCOMCY vous propose des services adaptés à vos besoins et à vos objectifs d’évolution. Entreprises, associations, institutions publiques ou privées, indépendants, établissements d’enseignement, professionnels de santé, NANCOMCY collabore depuis 10 ans avec de nombreux professionnels pour améliorer leur visibilité, gérer leur réputation, ou atteindre leurs objectifs stratégiques, tout en prenant en compte votre budget et les spécificités de votre activité.",
          active: false,
        },
        {
          question: "Quel est le rôle d’une agence de communication ?",
          answer:
            "Le rôle que NANCOMCY peut jouer dans votre communication peut être multiple. Nous vous aidons à comprendre les besoins et les attentes de vos clients et cibles en fonction desquels nous développons une stratégie marketing, élaborons des solutions personnalisées, évaluons l’efficacité de nos campagnes avant de vous en rendre compte. Nous travaillons en étroite collaboration avec vous pour développer et maintenir une image de marque positive et cohérente, renforcer votre notoriété et atteindre vos objectifs de communication.",
          active: false,
        },
      ],
    },
  },

  data() {
    return {
      faqMutable: this.faq,
    };
  },

  mounted() {
    this.setupFaqClickListeners();
  },

  beforeDestroy() {
    this.destroyFaqClickListeners();
  },

  methods: {
    handleFaqLinkClick(e) {
      e.preventDefault();
      this.$router.push({ name: e.target.getAttribute("aria-link") });
    },

    getLinks() {
      return document.querySelectorAll(
        'section.section-faq a[aria-link]:not([aria-link=""])'
      );
    },

    setupFaqClickListeners() {
      this.getLinks().forEach((link) => {
        link.addEventListener("click", this.handleFaqLinkClick);
      });
    },

    destroyFaqClickListeners() {
      this.getLinks().forEach((link) => {
        link.removeEventListener("click", this.handleFaqLinkClick);
      });
    },

    listing(index) {
      return index + 1 <= 9 ? "0" + (index + 1) : index + 1;
    },

    toggleQuestion(index) {
      this.faqMutable.forEach((faq, i) => {
        if (index !== i) {
          faq.active = false;
        }
      });
      this.faqMutable[index].active = !this.faqMutable[index].active;
    },
  },
};
