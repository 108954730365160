import { render, staticRenderFns } from "./PopupVideo.vue?vue&type=template&id=f2bd3ac0&scoped=true&"
import script from "./PopupVideo.vue?vue&type=script&lang=js&"
export * from "./PopupVideo.vue?vue&type=script&lang=js&"
import style0 from "./PopupVideo.vue?vue&type=style&index=0&id=f2bd3ac0&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2bd3ac0",
  null
  
)

export default component.exports