//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { createTypeformWidget } from "~/assets/js/typeform";

export default {
  props: {
    isPopup: {
      type: Boolean,
      default: true,
    },
    triggerOnLoad: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isPopupActive: false,
      isCookieActive: false,
    };
  },

  computed: {
    isNewsletterActive() {
      return this.$store.state.newsletter.isNewsletterActive;
    },
  },

  mounted() {
    this.getCookie();
    this.activePopup();
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.eventScroll);
    this.isPopupActive = false;
  },

  methods: {
    closeNewsletter() {
      this.$store.commit("newsletter/toggle");
    },

    initTypeform() {
      createTypeformWidget("iBLeEBIr", {
        container: document.querySelector("#form-ncy"),
        autoResize: "200, 450",
        hideHeaders: true,
        hideFooter: true,
        opacity: 0,
        disableScroll: true,
        inlineOnMobile: true,
        onSubmit: () => {
          this.setCookie();
          setTimeout(() => {
            this.closeNewsletter();
          }, 5000);
        },
      });
    },

    activePopup() {
      if (!this.isCookieActive && this.isNewsletterActive) {
        this.eventScroll = (e) => {
          if (window.scrollY > 900) {
            this.isPopupActive = true;
            this.initTypeform();
            e.currentTarget.removeEventListener("scroll", this.eventScroll);
          }
        };
        window.addEventListener("scroll", this.eventScroll);
        if (this.triggerOnLoad) {
          this.isPopupActive = true;
          this.initTypeform();
        }
      }
    },

    setCookie() {
      const date = new Date();
      const name = "newsletterNCY=active;";
      date.setTime(date.getTime() + 1000 * 60 * 60 * 24 * 365);
      const expires = "expires=" + date.toUTCString() + ";";
      document.cookie = name + expires + ";path=/";
    },

    getCookie() {
      const cookie = document.cookie;
      if (cookie.includes("newsletterNCY=active")) {
        this.isCookieActive = true;
      }
    },
  },
};
