// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/bg-temoignage.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".component[data-v-ad7c624e]{--cubic:cubic-bezier(0.17,0.03,0.37,0.99)}.container-picture[data-v-ad7c624e]{transition:transform 1s .3s var(--cubic);translate:0 50%;}@media (min-width: 768px){.container-picture[data-v-ad7c624e]{transform:translateY(0);translate:0}}.container-testi[data-v-ad7c624e]{transition:transform 1s var(--cubic);}@media (min-width: 768px){.container-testi[data-v-ad7c624e]{transform:translateX(30%)}}.swiper-slide-active .container-picture[data-v-ad7c624e]{transform:rotate(-5deg);}@media (min-width: 768px){.swiper-slide-active .container-picture[data-v-ad7c624e]{transform:rotate(-5deg) translateX(-70%)}}.swiper-slide-active .container-testi[data-v-ad7c624e]{transform:rotate(3deg);}@media (min-width: 768px){.swiper-slide-active .container-testi[data-v-ad7c624e]{transform:rotate(3deg) translate(30%)}}.section-testimonial[data-v-ad7c624e]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;background-position:50%;background-color:#fef6ec;}@media (min-width: 1024px){.section-testimonial[data-v-ad7c624e]{background:#fef6ec}}.btn-nav[data-v-ad7c624e]{background-color:#fdedda;padding:28px;border-radius:16px;transition:.3s;cursor:pointer}.btn-nav[data-v-ad7c624e]:hover{--tw-bg-opacity:1;background-color:rgba(253, 108, 67, var(--tw-bg-opacity))}.btn-nav:hover path[data-v-ad7c624e]{fill:#fdedda}.swiper-wrapper[data-v-ad7c624e]{transition-timing-function:var(--cubic)}@media (min-width: 768px){.aspect-none[data-v-ad7c624e]{aspect-ratio:auto}}.testimonial-not-active .swiper-slide-active .container-picture[data-v-ad7c624e],.testimonial-not-active .swiper-slide-active .container-testi[data-v-ad7c624e]{transform:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
